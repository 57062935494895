import React from "react"
import PropTypes from "prop-types"
import { Link, StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import Navbar from "react-bootstrap/Navbar"
import Nav from "react-bootstrap/Nav"
import Container from "react-bootstrap/Container"
import "bootstrap/dist/css/bootstrap.min.css"


const NavItem = props => {
  const linkText = (
    <>
      <i className={`fas fa-${props.icon}`}></i>&nbsp;
      <strong>{props.title}</strong>
    </>
  )
  let link
  if (props.href) {
    // Handle an internal link with Gatsby's Link component
    link = (
      <Link to={props.href}>
        {linkText}
      </Link>
    )
  } else if (props.ahref) {
    // Handle an external link with a standard anchor element
    link = (
      <a href={props.ahref} target={props.new_tab ? '_blank' : '_self'}>
        {linkText}
      </a>
    )
  }
  return (
    <div className="nav-item" style={{ margin: '10px' }}>
      <span style={{
        textAlign: 'left',
        marginTop: '10px',
        fontSize: '14px',
        textTransform: "uppercase",
        color: "#011323"
      }}>
        {link}
      </span>
    </div>
  )
}

const Header = ({ siteTitle }) => (
  <StaticQuery
    // This query retrieves the logo image for the site
    query={graphql`
      query {
        logo: file(relativePath: { eq: "cnda_logo.png" }) {
          childImageSharp {
            fixed(height: 60) {
              ...GatsbyImageSharpFixed_noBase64
            }
          }
        }
      }
    `}
    render={data => (
      <Navbar collapseOnSelect expand="lg" style={{margin: "1rem auto"}}>
        <Container>
          <div className="navbar-left">
            <a href="http://lisc-cnda.org">
              <Img fixed={data.logo.childImageSharp.fixed} />
            </a>
          </div>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="mr-auto">
            </Nav>
            <Nav>
              <NavItem ahref="https://lisc-cnda.org/awards" title="The awards" />
              <NavItem ahref="https://lisc-cnda.org/sponsors" title="Sponsors" />
              <NavItem ahref="https://lisc-cnda.org/about-cnda" title="About CNDA" />
              <NavItem href="https://lisc-cnda.org/past-events" title="Past Events" />
              <NavItem ahref="https://map.lisc-cnda.org" title="Past awardees" />
              <NavItem href="https://app.lisc-cnda.org" title="Apply" />
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    )}
  />
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
